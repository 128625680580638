import { Flex, FlexProps } from '@chakra-ui/core'
import Image from 'next/image'
import React from 'react'

type SponsorItemProps = FlexProps & {
  imgSrc: string
  imgMaxHeight: string[]
  blogUrl?: string
  name: string
}

const SponsorItem: React.FC<SponsorItemProps> = ({
  imgSrc,
  imgMaxHeight,
  blogUrl = '#',
  name,
  ...rest
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      width="80%"
      minHeight="66px"
      {...rest}
    >
      <a href={blogUrl}>
        <Flex
          height={imgMaxHeight}
          width={['90px', null, '100px']}
          position="relative"
        >
          <Image
            src={imgSrc}
            alt={name}
            layout="fill"
            objectFit="contain"
            sizes="50vw"
          />
        </Flex>
      </a>
    </Flex>
  )
}
export default SponsorItem
