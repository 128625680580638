import { Box } from '@chakra-ui/core'
import React from 'react'

import FeaturedProductsSection from '../../../shared/components/FeaturedProductSection/FeaturedProductsSection'

const FEATURED_PRODUCT_IDS: number[] = [3, 2, 47, 43]
const PRODUCT_SIZE_MAP: { [productId: number]: number } = { 47: 212, 43: 204 }

const FeaturedProductsContainer = () => {
  return (
    <Box bgColor="foam">
      <FeaturedProductsSection
        productIds={FEATURED_PRODUCT_IDS}
        productSizeMap={PRODUCT_SIZE_MAP}
      />
    </Box>
  )
}

export default FeaturedProductsContainer
