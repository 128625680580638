import forbesImage from '../../../../public/img/homepage/forbes-logo-black-transparent.png'
import gqImage from '../../../../public/img/homepage/GQ.png'
import mensHealthImage from '../../../../public/img/homepage/MensHealth_black.png'
import refinementImage from '../../../../public/img/homepage/RS_2018_refinement_black.png'
import vogueImg from '../../../../public/img/homepage/Vogue.png'
import { ImageDetails } from '../types'

export const FEATURED_IN_IMAGES: ImageDetails[] = [
  {
    src: forbesImage,
    maxHeight: ['40px', '36px', '31px'],
    blogUrl:
      'https://www.forbes.com/sites/forbes-personal-shopper/2021/07/07/subscription-boxes-for-men/?sh=72270b0774ce',
    name: 'forbes',
  },
  {
    src: vogueImg,
    maxHeight: ['22px', '24px', '24px'],
    name: 'vogue',
  },
  {
    src: gqImage,
    maxHeight: ['26px', '26px', '27px'],
    blogUrl: 'https://www.gq.com/story/best-moisturizers',
    name: 'gq',
  },
  {
    src: refinementImage,
    maxHeight: ['70px', '70px', '80px'],
    blogUrl:
      'https://www.rollingstone.com/product-recommendations/product-recommendations-lists/rolling-stone-essentials-top-products-2020-1071187/old-spice-swagger-deodorant-review-1073423/',
    name: 'rolling-stone',
  },
  {
    src: mensHealthImage,
    maxHeight: ['70px', '70px', '80px'],
    name: 'mens-health',
  },
]
