import { Flex } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomerReviewsSection from '../../../shared/components/CustomerReviewsSection/CustomerReviewsSection'
import FeaturedIn from '../../../shared/components/FeaturedIn/FeaturedIn'
import FeaturedProductsContainer from '../FeaturedProducts/FeaturedProductsOriginal'
import LittleBackgroundSection from '../LittleBackgroundSection'
import ShopHairAndBodySection from '../ShopHairAndBodySection'
import ShopSkinSection from '../ShopSkinSection'
import WhyLuminSection from '../WhyLuminSection'

const HomePageBody = () => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" flex={1}>
      <FeaturedIn width="100%" showPickYourSet={false} />
      <LittleBackgroundSection />
      <WhyLuminSection />
      <Flex direction="column" align="center" bgColor="offwhite" pb={8}>
        <ShopSkinSection />
        <ShopHairAndBodySection />
      </Flex>
      <FeaturedProductsContainer />
      <CustomerReviewsSection
        color="whiteSands"
        bgColor="sage"
        title={t(
          'home:well-let-some-of-the-guys-do-the-talking',
          'We’ll let some of the guys do the talking'
        )}
        dotsBgColor="whiteSands"
        showReviewsButton={true}
      />
    </Flex>
  )
}

export default HomePageBody
