import { FlexProps, Flex, Text, Box, Grid } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import PickYourSetSection from '../../../freeTrial/components/FreeTrialPageTemplate/PickYourSetSection/PickYourSetSection'
import { FEATURED_IN_IMAGES } from '../../../freeTrial/constants/featuredInConstants'
import SponsorItem from './SponsorItem'

type FeaturedInProps = FlexProps & {
  width: string | string[]
  heroSubtitleText?: string | React.ReactNode
  heroTitleText?: string
  showPickYourSet?: boolean
  display?: string[] | string
  bgColor?: string | string[]
}

const FeaturedIn: React.FC<FeaturedInProps> = ({
  width,
  heroSubtitleText,
  heroTitleText,
  showPickYourSet = true,
  display = 'flex',
  bgColor = ['white', 'white', 'seastone2'],
}) => {
  const { t } = useTranslation()
  const gridColumns = [
    ['span 2', null, 'span 1'],
    ['2 / span 2', null, 'span 1'],
  ]

  return (
    <Flex
      direction="column"
      width={width}
      align="center"
      justify="center"
      bgColor={bgColor}
      minHeight="220px"
      paddingBottom={[10, 10, 6]}
      display={display}
    >
      {showPickYourSet && (
        <Box
          bg={['seastone', 'seastone', 'seastone2']}
          pb={[10, 10, 0]}
          width="100%"
        >
          <PickYourSetSection
            heroSubtitleText={heroSubtitleText}
            position="relative"
            display={['flex', 'flex', 'none']}
            headerSize="lg"
            heroTitleText={heroTitleText}
          />
        </Box>
      )}

      <Text
        size="sm"
        paddingTop={[6, 6, 2]}
        fontWeight="bold"
        letterSpacing="1.5px"
        marginBottom={[2, 2, 1]}
      >
        {t('freeTrial:as-seen-in', 'AS SEEN IN')}
      </Text>
      <Grid
        gridTemplateColumns={['repeat(6, 1fr)', null, 'repeat(5, 1fr)']}
        gridTemplateRows={['repeat(2, 1fr)', null, '1fr']}
        minH={['200px', '200px', '120px']}
        width={['100%', '70%']}
        align={['center', 'center', 'center']}
        justify="center"
        px={[0, 0, 10]}
      >
        {FEATURED_IN_IMAGES.map((img, index) => {
          const isOneBeforeLast = FEATURED_IN_IMAGES.length - 2 === index

          return (
            <SponsorItem
              key={index}
              name={img.name}
              imgSrc={img.src}
              imgMaxHeight={img.maxHeight}
              blogUrl={img.blogUrl}
              mx={2}
              gridColumn={gridColumns[isOneBeforeLast ? 1 : 0]}
            />
          )
        })}
      </Grid>
    </Flex>
  )
}

export default FeaturedIn
