import { Flex, Text, Heading, Button, Link, Box } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureFlag } from '../../../../optimizely/hooks/useFeatureFlag'

export interface PickYourSetSectionProps {
  position: 'relative' | 'absolute'
  top?: number
  left?: number
  display?: string[]
  headerSize: 'lg' | 'md' | 'sm' | 'xs'
  heroSubtitleText?: string | React.ReactNode
  heroTitleText?: string
}

const PickYourSetSection: React.FC<PickYourSetSectionProps> = ({
  position,
  top,
  left,
  display,
  headerSize,
  heroSubtitleText,
  heroTitleText,
}) => {
  const displayValue = display ? display : 'flex'
  const { t } = useTranslation()

  const isNewCopy = useFeatureFlag('FREE_TRIAL_NEW_HERO_COPY', false)
  const defaultTitleText = isNewCopy ? (
    <>
      <Heading maxWidth="400px">
        {t('freeTrial:mens-skincare-made-easy', "Men's skincare made easy")}
      </Heading>
      <Heading mt={4} maxWidth="400px">
        {t(
          'freeTrial:start-your-free-trial-today',
          'Start your FREE Trial today'
        )}
      </Heading>
    </>
  ) : (
    <Heading maxWidth="400px">
      {t('freeTrial:enjoy-your-free-trial', 'Enjoy Your Exclusive Free Trial.')}
    </Heading>
  )

  return (
    <Flex
      direction="column"
      align="flex-start"
      justify="flex-start"
      position={position}
      top={top}
      left={left}
      display={displayValue}
      px={[5, 5, 24]}
      paddingTop={[10, 10, 12]}
      width="100%"
    >
      {heroTitleText ? (
        <Heading size={headerSize} maxWidth="400px">
          {heroTitleText}
        </Heading>
      ) : (
        defaultTitleText
      )}
      {heroSubtitleText &&
        (typeof heroSubtitleText === 'string' ? (
          <Text size="lg" py={[6, 6, 8]} maxWidth="400px">
            {heroSubtitleText}
          </Text>
        ) : (
          <Box py={[6, 6, 8]} maxWidth="400px">
            {heroSubtitleText}
          </Box>
        ))}
      <Link href={'#FreeTrialSetsSection'} width="100%">
        <Button
          variant="primary"
          width={[1, 1, '230px']}
          size="xl"
          fontSize={['0.85rem', '0.85rem', '1rem']}
          mt={4}
        >
          {isNewCopy
            ? t('freeTrial:get-started-today', 'Get started today')
            : t('freeTrial:pick-my-set', 'Pick My Set')}
        </Button>
      </Link>
    </Flex>
  )
}

export default PickYourSetSection
