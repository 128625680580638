import { Flex, Text } from '@chakra-ui/core'
import { Product, useCurrency } from '@pangaea-holdings/pangaea-checkout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch } from '../../../../redux/store'
import { useRTL } from '../../../i18n/hooks'
import { fetchMultipleProductsCached } from '../../../products/actions'
import productRoutes from '../../../products/routes'
import { ArrowForward } from '../Icons/ArrowForward'
import ShopifyNextLink from '../ShopifyNextLink'
import FeaturedProductCard from './FeaturedProductCard'

interface FeaturedProductsSectionProps {
  productIds: number[]
  title?: string
  productSizeMap?: { [productId: number]: number }
}

const FeaturedProductsSection: React.FC<FeaturedProductsSectionProps> = ({
  productIds,
  title,
  productSizeMap = {},
}) => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()

  const [products, setProducts] = useState<Product[]>([])
  const selectedCurrency = useCurrency()
  const dispatch = useTypedDispatch()

  useEffect(() => {
    dispatch(fetchMultipleProductsCached(selectedCurrency, productIds)).then(
      (fetchedProducts) => {
        setProducts(fetchedProducts.filter((p) => !!p))
      }
    )
  }, [selectedCurrency])

  return (
    <Flex
      direction="column"
      alignItems="center"
      textAlign="center"
      p={[5, 5, 12]}
    >
      <Text
        size="sm"
        fontWeight="bold"
        letterSpacing="1.1px"
        mb={8}
        mt={[5, 5, 4]}
        textTransform="uppercase"
      >
        {title ||
          t('products:shop-our-feature-products', 'shop our featured products')}
      </Text>

      {products?.length < 1 && (
        <Text
          color="sage"
          alignItems="center"
          fontSize="md"
          mt={[2, 2, 20]}
          mb={[10, 10, 12]}
        >
          {t('common:loading', 'Loading...')}
        </Text>
      )}
      <Flex
        flexWrap={'wrap'}
        justify="center"
        maxW="1200px"
        mt={[2, 2, 20]}
        mb={[10, 10, 12]}
      >
        {products.map((product) => (
          <FeaturedProductCard
            key={product.id}
            product={product}
            sizeId={productSizeMap[product.id]}
          />
        ))}
      </Flex>
      <Flex
        alignItems="center"
        _hover={{
          cursor: 'pointer',
          color: 'moss',
        }}
      >
        <ShopifyNextLink href={productRoutes.products()}>
          <Text size="md" fontWeight="bold">
            {t('products:see-all-products', 'See All Products')}
          </Text>
        </ShopifyNextLink>
        <ArrowForward
          fontSize="md"
          ml={isRTL ? 0 : 2}
          mr={isRTL ? 2 : 0}
          transform={isRTL ? 'rotate(180deg)' : 'initial'}
        />
      </Flex>
    </Flex>
  )
}

export default FeaturedProductsSection
