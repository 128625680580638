import React from 'react'
import { useTranslation } from 'react-i18next'

import productRoutes from '../../../../products/routes'
import HeroButtonSection from './HeroButtonSection'

import { BusinessModel } from 'modules/shared/types'

const HeroButtonProducts = () => {
  const { t } = useTranslation()

  return (
    <HeroButtonSection
      buttonVariant="outline-white"
      buttonTitle={t('common:shop-all-products', 'Shop All Products')}
      desc={t(
        'home:hero-section.shop-all-products-desc',
        'Not ready for a subscription? Shop all à la carte skin, hair, and body care.'
      )}
      href={productRoutes.products()}
      dataTestSelector="shop-all-products"
    />
  )
}

export default HeroButtonProducts
