export enum HomePageTabOption {
  BEST_SELLER = 'best-sellers',
  CATEGORY = 'category',
  CONCERN = 'concern',
}

export enum HOME_PAGE_HERO_LAYOUT_TYPE {
  SPLIT_IMAGES = 'split-images',
  VIDEO = 'video',
  FULL_IMAGE = 'full-image',
}

export type HOME_PAGE_HERO_BACKGROUND_CONFIG = {
  images?: {
    desktop: string[]
    mobile: string[]
  }
  videoUrl?: string
  showOverlay?: boolean
}
