import { Flex, Box, Text, Heading, Button } from '@chakra-ui/core'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

import productRoutes from '../../products/routes'
import ShopifyNextLink from '../../shared/components/ShopifyNextLink'

const WhyLuminSection = () => {
  const { t } = useTranslation()

  return (
    <Flex justify="center" color="drawerText" bgColor="foam">
      <Flex
        direction={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="flex-start"
        pt={[6, 6, 8]}
        px={5}
        pb={[10, 10, 24]}
      >
        <Flex
          direction="column"
          maxW="600px"
          justifyContent="center"
          mt={1}
          pr={[0, 0, 5]}
          pb={[0, 0, 5]}
          pl={[0, 0, 1]}
          w={[1, 1, 1 / 2]}
          minW={['0px', '324px', '324px']}
        >
          <Box h="100%" width="100%" position="relative">
            <Image
              src="https://cdn.shopify.com/s/files/1/2960/5204/files/lumin-next-homepage-5.png?v=1682602322"
              alt="lumin-product"
              blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsrSipBwAErQHicU9+1QAAAABJRU5ErkJggg=="
              placeholder="blur"
              width="100%"
              height="100%"
              layout="responsive"
              objectFit="contain"
            />
          </Box>
          <Text
            as="h6"
            size="sm"
            mt={4}
            letterSpacing="0.72px"
            color="drawerText"
            display={['none', 'none', 'block']}
          >
            {t(
              'home:the-complete-collection-line-up',
              'THE COMPLETE COLLECTION LINE UP'
            )}
          </Text>
        </Flex>
        <Flex
          direction="column"
          px={[0, 0, 5]}
          pt={[5, 5, 16]}
          pb={[0, 0, 5]}
          mt={[0, 0, 2]}
          w={[1, 1, 1 / 2]}
        >
          <Heading
            as="h2"
            mb={4}
            size="md"
            lineHeight={['28px', '28px', '80px']}
            letterSpacing={['0.19', '0.19', '0px']}
            mt={[0, 0, 10]}
            pt={1}
          >
            {t('home:why-lumin', 'Why Lumin?')}
          </Heading>
          <Text
            as="p"
            size="xl"
            maxW="489px"
            mb={[6, 6, 10]}
            letterSpacing={['0.03px', '0.03px', '0px']}
          >
            {t(
              'home:why-lumin-desc',
              'Hand back your partner/mom/sister’s eye cream, cause it just ain’t gonna cut it. Men’s skin is a whole other ball game—it’s thicker, tougher, and structured differently than women’s. We craft products tailored to your skin type and formulate regimens that actually take your skin issues head-on.'
            )}
          </Text>
          <ShopifyNextLink href={productRoutes.products()}>
            <Button
              variant="outline"
              size="lg"
              mt={[1, 1, 2]}
              whiteSpace="normal"
              minHeight={['50px', '50px', '60px']}
              w={['100%', '100%', '190px']}
              _hover={{
                bg: 'transparent',
                color: 'moss',
                borderColor: 'moss',
              }}
            >
              <Text size="md" fontWeight="bold">
                {t('home:build-your-routine', 'Build Your Routine')}
              </Text>
            </Button>
          </ShopifyNextLink>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default WhyLuminSection
