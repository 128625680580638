import React from 'react'

import HomePageBodyOriginal from '../modules/homePage/components/HomePageBody/HomePageBodyOriginal'
import TopSectionContainerOriginal from '../modules/homePage/components/HomeTopSection/TopSectionContainerOriginal'
import VoyageModal from '../modules/homePage/components/VoyageModal'
import MainLayout from '../modules/lumin/components/Layout/MainLayout'
import withLayout from '../modules/lumin/hoc/withLayout'
import PageHeader from '../modules/shared/components/PageHeader'

function HomePage() {
  return (
    <PageHeader title="Lumin | Effective, Simple, Affordable Men's Skincare">
      <TopSectionContainerOriginal />
      <HomePageBodyOriginal />
      <VoyageModal />
    </PageHeader>
  )
}

export default withLayout(HomePage, MainLayout, {
  pageType: 'home_page',
  enableRTL: true,
  variant: 'original',
})
