import { Box } from '@chakra-ui/core'
import React from 'react'

import { useRTL } from '../../../../i18n/hooks'

const HeroPromoVideo = ({ videoUrl }) => {
  const { isRTL } = useRTL()

  return (
    <Box minH="750px" w="100%" position="relative">
      <Box
        display={['none', 'block']}
        width="100%"
        height="750px"
        position="relative"
        zIndex="hide"
        bg="moss"
        overflow="hide"
      >
        <Box
          as="video"
          poster="/img/halloween-hero-poster.png"
          src={
            videoUrl ||
            'https://cdn.shopify.com/videos/c/o/v/ab4dca0e58524efb810d28eefa477918.mp4'
          }
          autoPlay
          muted
          controls={false}
          objectFit="fill"
          w="100%"
          loop
        />
      </Box>
    </Box>
  )
}

export default HeroPromoVideo
