import { Flex, Text, Heading, Button, Box } from '@chakra-ui/core'
import Image from 'next/image'
import React, { FC } from 'react'

import ShopifyNextLink from '../../shared/components/ShopifyNextLink'

interface ShopSectionProps {
  header: string
  desc: string
  buttonTitle: string
  buttonUrl: string
  imgUrl: string
  placeHolderUrl?: string
  direction: 'column-reverse' | 'column'
  rowDirection?: 'row-reverse' | 'row'
}

const ShopSection: FC<ShopSectionProps> = ({
  header,
  desc,
  buttonTitle,
  buttonUrl,
  imgUrl,
  direction,
  placeHolderUrl,
  rowDirection = 'row',
}) => {
  const isRowReverse = rowDirection === 'row-reverse'
  return (
    <Flex
      direction={[direction, direction, rowDirection]}
      justifyContent="center"
      alignItems="flex-start"
      pt={[10, 10, 12]}
      px={5}
      pb={[6, 6, 4]}
      color="drawerText"
      bgColor="offwhite"
    >
      <Flex
        direction="column"
        w={[1, 1, 1 / 2]}
        maxW="520px"
        pr={[0, 0, isRowReverse ? 0 : 5]}
        pl={[0, 0, isRowReverse ? 5 : 0]}
      >
        <Box pt={[6, 6, 12]} borderTopWidth="1px" borderTopColor="separator">
          <Heading
            as="h2"
            fontSize={['2.625rem', null, '6rem']}
            lineHeight={['1.75rem', null, '7.5rem']}
            letterSpacing={['0.25px', null, 0]}
            mb={[4, 4, 5]}
          >
            {header}
          </Heading>
          <Text
            as="p"
            size="lg"
            letterSpacing={['0.03px', null, 0]}
            maxW="374px"
            mb={[6, 6, 12]}
          >
            {desc}
          </Text>
          <ShopifyNextLink href={buttonUrl} w={['100%', '100%', '190px']}>
            <Button
              variant="primary"
              size="lg"
              mt={[0, 0, 1]}
              whiteSpace="normal"
              minHeight={['48px', '48px', '58px']}
            >
              <Text size="md" fontWeight="bold">
                {buttonTitle}
              </Text>
            </Button>
          </ShopifyNextLink>
        </Box>
      </Flex>

      <Flex
        direction="column"
        maxW="520px"
        justifyContent="center"
        px={[0, 0, isRowReverse ? 10 : 5]}
        pb={5}
        w={[1, '520px']}
      >
        <Image
          src={imgUrl}
          alt={header}
          width="100%"
          height="100%"
          layout="responsive"
          objectFit="contain"
          sizes="60vw"
        />
      </Flex>
    </Flex>
  )
}
export default ShopSection
