import { layerPush } from '../analytics/functions/track'

enum EVENTS {
  HOME_PAGE_START_FREE_TRIAL_BUTTON_CLICK = 'homePageStartFreeTrialButtonClick',
  HOME_PAGE_GET_STARTED_BUTTON_CLICK = 'homePageGetStartedButtonClick',
  HOME_PAGE_SHOP_BY_TAB_BUTTON_CLICK = 'homePageShopByTabButtonClick',
  HOME_PAGE_QUICK_ADD_BUTTON_CLICK = 'homePageQuickAddButtonClick',
}

export const trackHomeStartFreeTrialButtonClick = (hrefPath: string) => {
  return layerPush({
    event: EVENTS.HOME_PAGE_START_FREE_TRIAL_BUTTON_CLICK,
    hrefPath,
  })
}

export const trackHomeGetStartedButtonClick = (
  isOnHero: boolean,
  buttonCopy: string,
  isFreeTrialEnabled: boolean
) => {
  return layerPush({
    event: EVENTS.HOME_PAGE_GET_STARTED_BUTTON_CLICK,
    homepageEventsData: {
      is_on_hero: isOnHero,
      button_copy: buttonCopy,
      does_link_to_free_trial_flow: isFreeTrialEnabled,
    },
  })
}

export const trackHomeShopByTabButtonClick = (category: string) => {
  return layerPush({
    event: EVENTS.HOME_PAGE_SHOP_BY_TAB_BUTTON_CLICK,
    category_name: category,
  })
}

export const trackHomeQuickAddButtonClick = (productName: string) => {
  return layerPush({
    event: EVENTS.HOME_PAGE_QUICK_ADD_BUTTON_CLICK,
    homepageQuickAddEventsData: {
      product_name: productName,
    },
  })
}
