import { Flex, BoxProps, Box } from '@chakra-ui/core'
import React, { FC } from 'react'

import { useIsFreeTrialDisabled } from '../../../../freeTrial/hooks/useIsFreeTrialDisabled'
import HeroButtonFreeTrial from '../HeroButtons/HeroButtonFreeTrial'
import HeroButtonProducts from '../HeroButtons/HeroButtonProducts'
import HeroDesc from '../HeroDescOriginal'
import HeroLeftSection from './HeroLeftSection'
import HeroPromoVideo from './HeroPromoVideo'
import HeroRightSection from './HeroRightSection'

import {
  HOME_PAGE_HERO_BACKGROUND_CONFIG,
  HOME_PAGE_HERO_LAYOUT_TYPE,
} from 'modules/homePage/types'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'

const SplitHeroDesktopView: FC<BoxProps> = ({ display }) => {
  const isFreeTrialDisabled = useIsFreeTrialDisabled()
  const homePageHeroLayoutType = useFeatureFlag(
    'HOME_PAGE_HERO_LAYOUT_TYPE',
    HOME_PAGE_HERO_LAYOUT_TYPE.FULL_IMAGE
  )

  const optlyOverrideHeroBackground: HOME_PAGE_HERO_BACKGROUND_CONFIG =
    useFeatureFlag('HOME_PAGE_HERO_BACKGROUND', { showOverlay: true})

  return (
    <>
      <Flex display={display} position="relative">
        {optlyOverrideHeroBackground.showOverlay && (
          <Box
            top={0}
            w="100%"
            h="100%"
            pos="absolute"
            bg="black"
            opacity="0.6"
          />
        )}

        {homePageHeroLayoutType === HOME_PAGE_HERO_LAYOUT_TYPE.VIDEO && (
          <HeroPromoVideo videoUrl={optlyOverrideHeroBackground.videoUrl} />
        )}

        {homePageHeroLayoutType === HOME_PAGE_HERO_LAYOUT_TYPE.FULL_IMAGE && (
          <HeroLeftSection
            heroImage={optlyOverrideHeroBackground?.images?.desktop?.[0]}
            fullWidth
          />
        )}

        {(!homePageHeroLayoutType ||
          homePageHeroLayoutType ===
            HOME_PAGE_HERO_LAYOUT_TYPE.SPLIT_IMAGES) && (
          <>
            <HeroLeftSection
              heroImage={optlyOverrideHeroBackground?.images?.desktop?.[0]}
            />
            <HeroRightSection
              heroImage={optlyOverrideHeroBackground?.images?.desktop?.[1]}
            />
          </>
        )}
        <Box zIndex="0" top="0" position="absolute" w="100%" h="100%">
          <HeroDesc display={display} top="unset" h="100%" />
        </Box>
        <Flex
          justifyContent="space-between"
          w="100%"
          position="absolute"
          bottom="0px"
        >
          {isFreeTrialDisabled === false && (
            <HeroButtonFreeTrial color="black" bgColor="white" />
          )}
          <HeroButtonProducts />
        </Flex>
      </Flex>
    </>
  )
}

export default SplitHeroDesktopView
