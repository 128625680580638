import { Flex, Text, Heading, BoxProps, Link } from '@chakra-ui/core'
import htmlParser from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureFlag } from '../../../optimizely/hooks/useFeatureFlag'

const HeroDesc: FC<BoxProps> = ({ ...rest }) => {
  const { t } = useTranslation()

  const heroSubtextOverride = useFeatureFlag('HERO_SUBTEXT_COPY_OVERRIDE', null)
  const heroCopyOverride = useFeatureFlag('HERO_COPY_OVERRIDE', null)
  const heroAdditionalCopyOverride = useFeatureFlag(
    'HERO_ADDITIONAL_COPY_OVERRIDE',
    null
  )

  const heroAdditionalCopyOverrideLink = useFeatureFlag(
    'HERO_ADDITIONAL_COPY_OVERRIDE_LINK',
    null
  )

  const fontConfig = useFeatureFlag('HERO_COPY_OVERRIDE_FONT_CONFIG', {
    header: ['2.625rem', '2.875rem', '2.875rem'],
    heroAdditional: ['2.0rem'],
  })

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
      mx="auto"
      my="0"
      position="absolute"
      top={['-30%', '0', '0']}
      textAlign="center"
      color="whiteSands"
      minH="700px"
      {...rest}
    >
      <Text as="h5" size="xs" fontWeight="bold" letterSpacing="1.5px" mb={4}>
        {heroSubtextOverride
          ? htmlParser(DOMPurify.sanitize(heroSubtextOverride))
          : t('home:cleanse-hydrate-repair', 'CLEANSE. HYDRATE. REPAIR.')}
      </Text>

      <Heading
        as="h1"
        fontSize={fontConfig.header}
        letterSpacing="0.43px"
        lineHeight="3.18rem"
      >
        {heroCopyOverride
          ? htmlParser(DOMPurify.sanitize(heroCopyOverride))
          : t(
              'home:premium-care-for-the-premium-man',
              'Premium care for the premium man.'
            )}
      </Heading>
      {heroAdditionalCopyOverride && (
        <Heading
          as="h2"
          my="1rem"
          fontSize={fontConfig.heroAdditional}
          letterSpacing="0.43px"
          lineHeight={['1.5rem', '2.5rem']}
        >
          <Link
            as={heroAdditionalCopyOverrideLink ? 'a' : 'span'}
            fontFamily="inherit"
            fontSize="inherit"
            href={heroAdditionalCopyOverrideLink}
          >
            {heroAdditionalCopyOverride &&
              htmlParser(DOMPurify.sanitize(heroAdditionalCopyOverride))}
          </Link>
        </Heading>
      )}
    </Flex>
  )
}

export default HeroDesc
