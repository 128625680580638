import { Flex, Text, Button } from '@chakra-ui/core'
import { noop } from '@pangaea-holdings/pangaea-checkout'
import React, { FC } from 'react'

import ShopifyNextLink from '../../../../shared/components/ShopifyNextLink'

interface HeroButtonSectionProps {
  buttonVariant: string
  buttonTitle: string
  desc: string
  href?: string
  isInternal?: boolean
  className?: string
  onClick?: () => void
  dataTestSelector?: string
  btnColor?: string
  btnBgColor?: string
}

const HeroButtonSection: FC<HeroButtonSectionProps> = ({
  buttonVariant,
  buttonTitle,
  desc,
  href,
  isInternal = true,
  className,
  onClick = noop,
  dataTestSelector,
  btnColor,
  btnBgColor,
}) => {
  const button = (
    <Button
      variant={buttonVariant}
      size="xl"
      w="185px"
      minH="56px"
      mb={8}
      whiteSpace="normal"
      onClick={onClick}
      color={btnColor}
      bgColor={btnBgColor}
      data-test-selector={dataTestSelector}
    >
      <Text size="md" fontWeight="bold">
        {buttonTitle}
      </Text>
    </Button>
  )

  return (
    <Flex
      direction="column"
      align="center"
      justify="flex-end"
      grow={1}
      h="100%"
      color="offwhite"
      textAlign="center"
      className={className}
      zIndex="900"
    >
      <Text fontSize="1.125rem" lineHeight="1.93rem" maxW="325px" mb={5}>
        {desc}
      </Text>
      {href ? (
        <ShopifyNextLink href={href} isInternal={isInternal}>
          {button}
        </ShopifyNextLink>
      ) : (
        button
      )}
    </Flex>
  )
}

export default HeroButtonSection
