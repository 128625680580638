import { Box } from '@chakra-ui/core'
import Image from 'next/image'
import React from 'react'

import defaultHeroImg from '../../../../../../public/img/homepage/Lumin_Hero_HP2_1.jpeg'
import { useRTL } from '../../../../i18n/hooks'

const HeroRightSection = ({ heroImage }) => {
  const { isRTL } = useRTL()

  const bgImage = heroImage || defaultHeroImg

  return (
    <Box minH="750px" w="50%" position="relative">
      <Box
        display={['none', 'flex']}
        width="100%"
        height="750px"
        transform={isRTL ? 'scale(-1, 1)' : 'initial'}
        position="relative"
        zIndex="hide"
        bg="moss"
      >
        <Image
          alt="face moisturizer content"
          src={bgImage}
          layout="fill"
          objectFit="cover"
          sizes="70vw"
        />
      </Box>
    </Box>
  )
}

export default HeroRightSection
