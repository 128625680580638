import { Box, Button, Flex, Heading, Text } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../design/components'
import { slugify } from '../../../utils/string'
import { trackVoyageSubscription } from '../../analytics/functions/track'
import { useFeatureFlag } from '../../optimizely/hooks/useFeatureFlag'
import ShopifyNextLink from '../../shared/components/ShopifyNextLink'

type VoyageConfig = {
  delay: number
  title: string
  sms_text: string
  cta_text: string
}

const createLink = (smsText: string): string => {
  if (!process.browser) {
    return '' // do nothing we are on server
  }
  const win = window as Window
  const phoneNumber = '71023' // Supplied by Voyage
  const userAgent = win.navigator.userAgent.toLowerCase()
  let smsUrlSeparator = '?'
  let phoneSeparator = '//'
  if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
    smsUrlSeparator = '&'
    phoneSeparator = ''
  }
  return `sms:${phoneSeparator}${phoneNumber}${smsUrlSeparator}body=${smsText}`
}

const VoyageModal = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const config: VoyageConfig = useFeatureFlag('VOYAGE_CONFIG', {})

  useEffect(() => {
    if (config.delay !== null) {
      setTimeout(() => {
        setIsModalOpen(true)
      }, config.delay)
    }
  }, [config])

  const subscribeSMS = () => {
    if (!process.browser) {
      return
    }
    const win = window as Window
    // Note: timeout required for Chrome on iOS
    setTimeout(() => {
      setIsModalOpen(false)
      trackVoyageSubscription({ subscribed: true })
      win.open(createLink(config.sms_text), '_parent')
    }, 300)
  }

  const handleCancel = () => {
    trackVoyageSubscription({ subscribed: false })
  }

  // Don't show modal if we switch off from optimizely.
  if (!config.title) {
    return null
  }

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      onDismiss={handleCancel}
    >
      <Flex direction="column" textAlign="center" w="100%" maxW="550px">
        <Box
          as="img"
          src="https://cdn.shopify.com/s/files/1/0044/1237/5107/files/Luminskin_2x_d3c475e6-7622-447a-bdc5-cfe696be8c97.png?v=1599223195"
          w="100%"
        />
        <Heading as="h4" size="sm" fontWeight="bold" mt={10} mb={3}>
          {t(`voyage:${slugify(config.title.substr(0, 10))}`, '{{title}}', {
            title: config.title,
          })}
        </Heading>
        <Text>
          {t(
            `voyage:unlock-your-first-offer`,
            'Join us to unlock your first offer and other exclusive deals only for SMS subscribers.'
          )}
        </Text>
        <Button onClick={subscribeSMS} minW={['100%']} my={6}>
          {t(`voyage:${slugify(config.cta_text)}`, '{{cta_text}}', {
            cta_text: config.cta_text,
          })}
        </Button>
        <Text fontSize="xs">
          {t(
            'voyage:terms-and-conditions',
            'By joining via text message, you agree to receive recurring marketing messages from Lumin at the mobile number used to send the join text message. Msg & data rates may apply. Msg frequency is recurring. Consent is not a condition of any purchase. Text STOP to unsubscribe, HELP for help. View'
          )}
          &nbsp;
          <ShopifyNextLink
            isExternal
            textDecoration="underline"
            href="https://assets.voyagetext.com/terms/6f6955f6-04da-477e-89fe-22d026b9abcd.html"
          >
            {t('common:terms-and-privacy', 'Terms & Privacy')}
          </ShopifyNextLink>
        </Text>
      </Flex>
    </Modal>
  )
}
export default VoyageModal
