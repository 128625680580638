import { Box } from '@chakra-ui/core'
import Image from 'next/image'
import React from 'react'

import { useIsMobileWidth } from 'design/hooks/useWindowDimensions'

const HeroLeftSection = ({ heroImage, fullWidth = false }) => {
  const isMobile = useIsMobileWidth()

  const defaultHeroImg =
    'https://cdn.shopify.com/s/files/1/2960/5204/files/lumin-next-homepage-image-6.png?v=1683280548'

  const bgImage = heroImage || defaultHeroImg

  return (
    <Box minH="750px" w={fullWidth ? '100%' : '50%'} position="relative">
      <Box
        display={['none', 'flex']}
        width="100%"
        height="750px"
        position="relative"
        zIndex="hide"
        bg="moss"
      >
        <Image
          alt="hero image"
          src={bgImage}
          layout="fill"
          objectFit="cover"
          priority={!isMobile}
          sizes="70vw"
          objectPosition="bottom center"
        />
      </Box>
    </Box>
  )
}

export default HeroLeftSection
